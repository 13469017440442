// import { GoogleOAuthProvider } from "@react-oauth/google";
import HomePage from "./13-university-classic";
import BackToTop from "./backToTop";

export default function Home() {
  return (
    <>
      <main className="">
      {/* <GoogleOAuthProvider clientId="77524109234-cr3bf2782g8b3iikljs1kabk3155jlrl.apps.googleusercontent.com"> */}
        <HomePage />
        <BackToTop />
        {/* </GoogleOAuthProvider> */}
      </main>
    </>
  );
}
